.xm-grid-header .logo img {
    max-height: 133px;
    margin-top:10px;
}
.xm-grid-header .module-logo {
	text-align:center;	
}
.desktop-header-multi {
	margin-bottom:15px;	
}
.module-search .form-search .button-search .fa {
	color:var(--white) !important;	
}
.xm-mega-menu-categories .nav-item a span {
	font-size: 18px;
    font-weight: 300;
}
.module-category-menu .nav {
	width:100% !important;	
}
.desktop-nav-menu {
	margin-left:0;	
}
.header-container {
	background:var(--white);
	margin-bottom:40px;
}
.hide-above-960 .module-search .form-search .button-search .fa {
	
}

.hide-above-960 .module-search .form-search .button-search {
	top: -2px;
    right: 15px;
}

.hide-above-960 .module-search .form-search .button-search .fa {
	color:unset !important;	
}
.hide-above-960 .module-header-multi .item {
	margin: 5px auto;	
}
.hide-above-960 .module-search {
	margin:10px auto;	
}

.hide-above-960 .item-switch-language {
	position: fixed;
    float: unset;
    right: 10px;
    top: 30px;	
}