#footer {
	background:var(--darkBlue);	
	color:var(--white);
	margin-top:90px;
}

.footer,.footer-primary-container {
	width:100%;
	margin:0;	
}

.footer-copyright {
	text-align:center;
	color:var(--white);	
}

.footer-bottom .item-left {
	text-align:center;	
	width:100%;
}
#footer {
	word-break: break-word;	
}
#footer .heading {
	color:var(--white);
	font-weight:bold;
	font-size:1.5em;
	margin-top:10px;	
}

.footer-top-container {
	background:unset;	
}

#footer a {
	color:var(--white);	
}

#footer .fa {
	padding: 0 10px;
	font-size:3.5em;	
}

/*Footer*/
ul.bullet li {
    background: none;
    padding-left: 0px;
    text-transform: capitalize;
    font-family:var(--fontFam);
    letter-spacing: 0.5px;
}

#footer ul li a {
    text-decoration: none;
    line-height: 1.75em;
    color: var(--white);
    font-size: 1.2em;
    margin:5px auto;
    font-weight: 400;
}

#footer p {
	margin:5px auto;
	font-size:1.2em;
}

#footer ul li a:hover {
    border-bottom: 1px solid var(--white);
}
.suggestion-box {
	background:var(--gray);
	color:var(--white);
	width:100%;
	text-align:center;
}
#footer .fa-inbox {
	font-size:1.5em;
	padding:0 10px;	
}
@media screen and (max-width: 1000px) {
	#footer {
		font-size: 12px;
    	word-break: break-word;
	}
	#footer .footer {
		padding:0;	
	}
	.footer .section div {
		float: unset;
	    display: inline-block;
	    margin: 0 auto;
	    text-align: center;	
	}
	.footer .logo {
		max-width:25%;	
	}
	#footer .footer-primary {
		padding:0;
	}
}