/* Home */

.b2b-featured-items,.b2c-featured-items {
	float:none;
	display:block;	
	margin-top:30px;
}

.featured-item .section-title {
	border: 0;
    position: absolute;
    top: 20px;
    background: var(--red);
    color:var(--white);
    font-weight:600;
    letter-spacing:1px;
    text-transform:uppercase;
    margin-left:-1px;
    border-radius:15px 0 0 0;
    padding:8px 20px;
}
.featured-item .section-title::after {
	content: '';
	width:20px;
	height:100%;
	background:blue;	
}

.featured-item {
	min-height:250px;
	margin:38px 15px 15px 15px; 
    border-color: red;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.fi-text-left {
	position: absolute;
    top: 10px;
    left: 9px;
    color: var(--white);
    text-align: right;
    line-height: 27px;
    -webkit-font-smoothing: antialiased;
}

.cta-fi-br {
	position: absolute;
    bottom: 2px;
    right: 20px;
    color: var(--white);
    text-align: left;
}

.cta-fi-tr {
	position: absolute;
    top: 2px;
    right: 20px;
    color: var(--white);
    text-align: right;
}

.cta-fi-tl {
	position: absolute;
    top: 2px;
    left: 20px;
    color: var(--white);
    text-align: left;
}

.cta-fi-bl {
	position: absolute;
    bottom: 2px;
    left: 20px;
    color: var(--white);
    text-align: left;
}

.cta-fi button {
	font-size: 1.2em;
    padding: 9px;
    background: var(--white);
    border: 3px;
    border-color: blue;
    border-style: solid;
    border-radius: 20px;
    font-weight: 700;
}

h2 {
	font-weight:200;	
}

.home .page-content h2 {
	text-align:center;
	color:var(--darkerBlue);
	margin:30px 25px 15px 25px;
}

.home .page-content p {
	margin:0 20%;	
	color:var(--darkerBlue);
	text-align:center;
}

.home .newsletter-div {
	background:var(--gray);
	color:var(--white);
	width:100%;
	margin:50px auto;
}

.home .newsletter-div p,.home .newsletter-div h2 {
	color:var(--white);
}

.home .newsletter-wrapper {
	background:none;
	width:33%;
	margin:0 auto;	
}
#top > div > div > div > div.col-main.grid-full.in-col1 {
	width:100%;
	margin:0;	
}
.newsletter-button span {
	padding:10px !important;
	width:100%;	
}
#subscribe-form {
	margin-bottom:25px;	
}
.special-thanks .owl-carousel .owl-item img {
	width:70%;
	margin:0 auto;	
}

@media screen and (max-width: 1000px) {
  #subscribe-form {
    width:100%;
  }
  #subscribe-form input {
    margin:10px auto;
  }
  .home .newsletter-wrapper {
  	width:100%;	
  }
}