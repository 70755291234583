@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700,800,900';
@import 'https://fonts.googleapis.com/css?family=Material+Icons';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v134/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}


/* ================================================ */
/* Init */
/* ================================================ */
body {
    font-family:var(--fontFam);
    font-weight: 400;
    color: var(--darkBlue);
    background-color:#FFFFFF;
    width: auto!important;
    overflow-x: hidden!important;
    font-size:14px;
}

a {
    -moz-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
    color: var(--darkBlue);
}

a > i {
    font-style: normal;
}

.wrapper {
    border-top: none;
    width: auto!important;
    overflow-x: hidden!important;
}

.xm-grid-header > .container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.main {
    padding: 30px 0 0;
}

.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

button.button {
    -webkit-border-fit: lines;
    overflow: visible;
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}

button.button span {
    text-transform: uppercase;
    border-radius: 30px;
    background-color: var(--gray);
    color: #333;
    float: left;
    display: block;
    padding: 0;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
}

.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span {
    background-color: var(--darkBlue);
    color: #fff;
}

button.button span span {
    font-family:var(--fontFam);
}

.buttons-set button.button {
    margin-left: 0;
    margin-right: 5px;
    margin-bottom: 5px;
}

.button.btn-inline span, .button.btn-checkout span {
    background-color: var(--darkBlue);
    color: #fff;
}
button.add-cart-button {
 margin-right: 15px !important;
}

@media only screen and (min-width: 961px) {
    .show-below-960 {
        display: none !important;
    }
}

@media only screen and (max-width: 959px) {
    .hide-below-960 {
        display: none !important;
    }
}

/* ================================================ */
/* Font and Colours */
/* ================================================ */
.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family:var(--fontFam);
    font-weight: 700;
    text-transform: uppercase;
}

#root-wrapper a:hover span.fa:before, .header-top a:hover, #nav .nav-panel--dropdown a:hover, p.required, .product-options dt label.required em, .form-list label.required em, a:hover, .vertnav li.current > a, .header .dropdown-menu a:hover, .header .open > .dropdown-toggle.cover > div a:hover, .header .form-search .search-autocomplete li:hover {
    color: var(--darkBlue);
}

.header-top-container {
    text-transform: uppercase;
}

nav.nav-container,
.header-primary-container,
.header-top-container {
    background-color: var(--darkBlue);
}

.main-container {
    background-color: #FFF;
    padding: 0 !important;
}

.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
    color: #000;
}

/* ================================================ */
/* Header */
/* ================================================ */
.isPublic .item-logo {
	margin:0 auto;
	float:none;
	width: auto;
	display:inline-block;	
}
.isPublic .welcome-msg {
     display:none;
}
.isPublic .header-top {
     text-align:center !important;
}
header#header.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: none;
}

.header .userCode,
.header .userName,
.header .userSwitch,
.header .userSwitch a,
.header .welcome-msg {
    font-weight: 700;
    font-size: 11px;
    color: var(--darkBlue) !important;
    margin: 5px 0 !important;
}

.header .userSwitch {
    margin-left: 10px !important;
}

.header .userSwitch a,
.header .userSwitch a:hover {
    border-bottom: 1px solid #aaa;
}

.header-container {
    position: relative;
}

.header-primary {
    padding: 0;
    position: relative;
}

.header-top, .header .dropdown {
    line-height: 0;
}

nav.nav-container, .header-primary-container, .header-top-container {
    background-color: #FFFFFF;
}

header#header .top-links > .links > li > a, header#header .links-container > .links > .company > dl.company-links dt a {
    color: #aaa !important;
    border: none;
    background-image: none;
}

header#header .top-links > .links > li > a:hover {
    color: #fff !important;
}

.user-menu {
    margin: 0;
}

#mini-cart .feature-icon-hover a {
    color: var(--darkBlue);
}
 .links>li, .links>li>a {
     line-height: 2em;
}
 .link-register {
     display: none !important;
}
.links > li > a:hover {
    background-color: var(--darkBlue);
    color: #fff;
}
.dropdown-toggle * {
    display: inline-block;
}

.welcome-msg, .userCode, .userName, .userSwitch > a {
    color: #fff;
}
/* ================================================ */
/* Menu */
/* ================================================ */
.nav-item--home {
	display:none !important;	
}
.nav.has-outline>.grid-full {
    margin-top: 0;
    margin-bottom: 0;
}
#nav {
    z-index: 1000;
    position: relative;
}

#nav, .mobnav-trigger {
    border-bottom: none;
}

.mobnav-trigger,
.mobnav-trigger.active,
.mobnav-trigger:hover {
    background-color: transparent;
}

.nav-regular {
    background-color: transparent;
}

#nav.nav-mobile ul.level0 .nav-item.current > a, #nav.nav-mobile ul.level0 .nav-item > a:hover, .cms-index-index .nav-regular .nav-item--home > a, .nav-mobile .nav-item.level0.current > a, .nav-mobile .nav-item.level0 > a:hover, .nav-regular .nav-item.level0.active > a, .nav-regular .nav-item.level0:hover > a {
    background-color: transparent;
}

.nav-container a, .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a, .mobnav-trigger a {
    color: var(--darkBlue);
}

.nav-regular .nav-item.level0:hover > a, .nav-mobile .nav-item.level0 > a:hover {
    color: var(--darkBlue);
    background-color: #fff;
}

.nav-item.active {
    background-color: #fff;
}
.nav-item.active > a,
.module-sub-categories-title.active{
    color: var(--darkBlue) !important;
}
.nav-item.active > a .caret {
    border-top-color: var(--darkBlue) !important;
}

.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family:var(--fontFam);
    font-weight: 700;
    text-transform: inherit;
}
h3 {
    font-size: 16px;
}
.products-grid .product-name, .products-list .product-name, h3.product-name {
    font-size: 13px;
}

.block .block-title {
    border-bottom: none;
    padding-left: 10px;
    position: relative;
}

.block .block-title .mh-filters-btn {
    position: absolute;
    right: 0;
    top: 0;
}

.block .block-title .menu-icon {
    padding: 0 10px !important;
}

.sorter .amount {
    color: #aaa;
}

.nav-regular li.level0 > a {
    line-height: 40px;
}

.nav-regular li.level0 > a > span {
    display: inline-block;
    white-space: nowrap;
}

#nav .nav-item.level0.parent > a .caret {
    border-top-color: #fff;
}

#nav .nav-item.level0.parent:hover > a .caret {
    border-top-color: var(--darkBlue);
}

.nav-regular .mega > .nav-panel--dropdown, .nav-regular li.level0 > .nav-panel--dropdown {
    border-top: none;
    width: 100vw !important;
}

.nav-regular li.level0 > .nav-submenu.nav-panel--dropdown {
    width: 16em !important;
}

.nav-mobile.opt-sb2 li.level0>div>div>.nav-block {
    padding: 0 10px;
}

.nav-mobile .opener:before {
    content: "\f107" !important;
    padding: 10px 10px 10px 20px !important;
}

.nav-mobile .opener.active:before, .nav-mobile li.active > .opener:before {
    content: "\f106" !important;
}

.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}

.form-search .button i {
    font-style: normal;
}

.breadcrumbs .nav-filter {
    float: right;
    font-size: 16px;
    font-weight: 700;
}
#breadcrumbs a {
    text-transform: uppercase;
}
#filter_list_box dt {
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
}
.category-products .toolbar {
    border: none;
    width: 100%;
    text-align: right;
}
.category-products .toolbar .sorter {
    display: inline-block;
}
.category-products-grid.hover-effect .item:hover, .products-list.hover-effect .item:hover {
    margin-left: 0;
    margin-right: 0;
    box-shadow: none;
    z-index: 0;
}
.category-products-grid.hover-effect .item:hover {
    padding-left: 1%;
    padding-right: 1%;
}
.products-list.hover-effect .item:hover {
    padding-left: 0;
    padding-right: 0;
}
.sidebar a, .accordion-style1 li a {
    text-transform: uppercase;
}
.mini-products-list li {
    list-style: none;
}
.breadcrumbs li {
    display: inline-block;
    vertical-align: top;
    font-weight:700;
}
.product-shop h1 {
    font-size: 20px;
}
.product-shop .qty-wrapper label {
    display: none;
}
@media only screen and (max-width: 960px) {
    .form-list .field,
    .form-list input.input-text,
    .form-list select,
    .progress, div[class^=strength_meter_passwd] {
        width: 100%;
    }
    
    .nav-item.active > a,
    .module-sub-categories-title.active{
        color: var(--darkBlue) !important;
        background-color: var(--darkBlue) !important;
    }
    .nav-item.active > .opener {
        color: #fff !important;
    }
    .xm-grid-product .grid-left,
    .xm-grid-product .grid-right {
        width: 100% !important;
    }
    .product-shop h1,
    .product-shop .product-type-data {
        text-align: center !important;
    }
    .grid-col2-sidebar,
    .product-search-container .sidebar-category,
    .product-search-container .has-sidebar.grid-col2-main {
        width: 100%;
    }
    .details-main-container .breadcrumbs {
        display: none;
    }
    .sorter .sort-by {
        display: none;
    }
    .links>li>a {
        padding: 0 10px;
    }
    .product-shop {
        margin-top: 20px;
    }
    .sidebar-category .block-content {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .sidebar-category .accordion{
        overflow-x: scroll;
        width: max-content;
    }
    .mm-panels .sidebar-category .accordion {
        width: 100% !important;
    }
    .sidebar-category .accordion li{
        display: inline-block;
        width: auto !important;
    }
    .mm-panels .sidebar-category .accordion li{
        display: block;
        width: 100% !important;
        text-align: left;
    }
    .grid-full, .grid12-12 {
        overflow: auto;
        margin-bottom:0;
    }
    .nav-item .header-top-container {
        background-color: #fff;
    }
    .search-wrapper-mobile {
        width: 36px !important;
        height: 36px;
        margin: 0 !important;
        padding: 0;
        float: none;
        vertical-align: top;
    }
    .search-wrapper-mobile .form-search input {
        display: none;
    }
    .mobnav-trigger-wrapper {
        display: inline-block !important;
        vertical-align: top;
        float: none !important;
        padding: 10px .5em !important;
    }
    .mobnav-trigger {
        padding: 0;
    }
    .mobnav-trigger > span:last-child {
        display: none;
    }
    .mobnav-trigger .trigger-icon {
        padding-top: 0;
        margin: 0;
    }
    .mobnav-trigger .trigger-icon .line {
        background-color: #fff;
    }
    .search-wrapper-centered {
        float: none !important;
        display: inline-block !important;
    }
    #mini-cart .feature-icon-hover .hide-below-960,
    #mini-cart .feature-icon-hover .empty,
    nav .feature-icon-hover .hide-below-960,
    nav .feature-icon-hover .empty{
        display: none;
    }
    nav .feature-icon-hover {
        padding: 0 !important;
    }
    nav .feature-icon-hover .caret {
        display: none;
    }
    nav.nav {
        text-align: right;
        height: 36px;
    }
    nav.nav .header-top {
        margin: 0;
        padding: 0;
        width: 100%;
        display: block !important;
    }
    .nav-mobile {
        text-align: left;
    }
    .nav-logo, .nav-cart, .nav-search {
        display: inline-block;
        vertical-align: top;
    }
    .nav-logo {
        float: left;
        width: 200px;
        margin-top: 5px;
    }
    .nav-cart {
        height: 36px;
    }
    .nav-cart .fa-shopping-cart {
        font-size: 1.5em !important;
        font-style: normal;
    }
    .nav-cart .hide-below-960 {
        visibility: hidden;
        position: relative;
        width: 0;
        height: 0;
        display: block !important;
    }
    .nav-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        background-color: var(--darkBlue);
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
    }
    .nav-cart .product-details a {
        color: #888;
    }
    .nav-cart .btn-remove,
    .nav-cart .btn-edit {
        float: right;
    }
    .nav-cart .empty, .nav-cart .caret {
        display: none;
    }
    
    .nav-search .input-text {
        display: none;
    }
    .nav-search .button {
        height: 36px;
    }
    .cart-mobile {
        display: inline-block !important;
        vertical-align: top;
    }
    .nav-item .header-top {
        display: block !important; 
    }
    .header-top .item {
        display: inline-block;
        vertical-align:middle;
        margin:0 auto;
    }
    .nav-mobile .nav-block {
        display: initial;
    }
    .nav-mobile .nav-block,
    .nav-mobile .nav-block > .grid12-12 {
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
    }
    .nav-mobile .nav-block > .grid12-12 {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .module-sub-categories .nav-item {
        width: 100% !important;
    }
    .module-sub-categories .nav-item > a {
        padding: 15px 54px 15px 20px !important;
        text-transform: uppercase !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    .module-sub-categories .nav-item .page-item {
        padding: 0 !important;
    }
    .module-sub-categories .nav-item .page-item > a {
        padding: 15px 10px 15px 40px !important;
        display: block;
        text-transform: uppercase;
    }
    .acco .module-sub-categories div.nav-item ul li {
        padding-left: 0 !important;
    }
    .nav-mobile .nav-panel-inner .opener {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .nav-mobile li.nav-item {
        font-size: 14px;
        background-color: #f7f7f7;
    }
    .nav-mobile .nav-item.parent a {
        background-color: transparent;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        font-weight: 400;
    }
    .header-container .col-main {
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .nav-item.show-below-960 {
        padding: 10px !important;
    }
    .nav-item.show-below-960 > div {
        display: block !important;
        padding: 0.3em 1em;
    }
    .nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: #fff;
    }
    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }
    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }
    .nav-search.fly-out .form-search .button {
        color: #aaa;
        top: 10px;
        right: 10px;
    }
    .nav-search.fly-out .form-search .button-search {
        right: 45px;
    }
    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }
    .nav-search.fly-out .form-search .button-close {
        display: block;
    }
}
@media (max-width: 600px) {
    #my-menu {
        display: none !important;
    }
}
/* ================================================ */
/* Content: Logo and Search  */
/* ================================================ */

.search-wrapper-centered {
    margin-top: 18px;
    margin-bottom: 7px;
}

.search-wrapper-centered .form-search {
    margin: 0 auto;
}

.search-wrapper-centered .form-search label {
    display: none;
}


.form-search .button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.375em;
    color: #fff;
    padding: .5em;
}

@media only screen and (min-width: 960px) {
    .user-link-wrapper {
        float: right;
    }
    .search-wrapper .form-search {
    	width:100%;	
    }
	*::-webkit-input-placeholder { color: var(--darkBlue) !important; }
	
	
	*:-moz-placeholder { color: var(--darkBlue) !important; }
	
	
	*::-moz-placeholder { color: var(--darkBlue) !important; }
	
	
	*:-ms-input-placeholder { color: var(--darkBlue) !important; }
    .search-wrapper .form-search .input-text {
        width: 36px;
        padding-right: 36px !important;
        background-color: transparent;
        z-index: 1;
        position: relative;
        color: #555;
        float: right;
        border: none;
    }

    .search-wrapper .form-search .input-text:hover, .search-wrapper .form-search .input-text:focus {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        color: #555;
        transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -webkit-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
    }
}

@media only screen and (min-width: 768px) {
    .logo-wrapper > a {
        margin: 0 auto !important;
    }
}

/* ================================================ */
/* Content: Product Listing  */
/* ================================================ */

/* ================================================ */
/* Content: Product Detail  */
/* ================================================ */
.product-shop .price-box .regular-price .price {
    font-size: 2em;
}

.matrix-table .price-box .regular-price .price {
    font-size: 1em;
}

.product-shop .btn-cart span span {
    padding: 5px 40px;
    font-size: 1.1666em;
}

.product-shop .z4 {
    background-color: var(--darkBlue) !important;
}

.img-box-style1 .product-image {
    border: none;
    padding: 0;
}

.product-shop h1 {
    color: var(--darkBlue);
}
.product-shop ul {
    list-style: disc inside;
}

.product-shop .product-options dd {
    padding-left: 0;
}

.price-box .regular-price .price, .price-box .minimal-price .price, .price-box-bundle .full-product-price .price {
    color: #000000;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-family:var(--fontFam);
}

.product-options dt label, .product-shop .qty-wrapper label {
    font-size: 1.3em;
}

.product-shop .qty-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
}

.product-shop .qty-wrapper input {
    font-size: 1.2em;
    min-width: 150px;
    width: 100% !important;
}

.product-shop button.add-cart-button {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0;
}

.product-shop button.add-cart-button span {
    width: 100%;
    padding: 0;
    color: #fff;
    background-color: var(--darkBlue);
}

.product-shop button.add-cart-button:hover span,
.product-shop button.add-cart-button span:hover {
    background-color: var(--darkBlue) !important;
}

.product-shop .short-description {
    font-size: 16px;
    line-height: 1.75;
    padding-top: 32px;
    padding-bottom: 24px;
    font-weight: 100;
}

.product-shop ul li {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop p {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop .product-type-data {
    float:left;
}

.variations .swatches-con.list, .variations .swatchesCon.list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.variations .swatches-con.list .swatch, .variations .swatchesCon.list .swatch {
    display: inline-flex !important;
}

.variations .swatches-con.list .swatch .text, .variations .swatchesCon.list .swatch .text {
    height: 46px !important;
    line-height: 46px !important;
    width: 100%;
    border-radius: 4px;
    font-size: 1.3em !important;
    overflow: hidden;
}

.variations .swatches-con.list .swatch.selected .text {
    border: 1px solid #000 !important;
    background-color: #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    color: #fff !important;
    font-size: 1.3em !important;
}

.variations .swatches-con.list .swatch.available:hover .text {
    border: 1px solid #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    font-size: 1.3em !important;
}

/* ================================================ */
/* Content: Others  */
/* ================================================ */
#mini-cart .actions button {
	margin-top:10px;	
}
.home .owl-stage {
		margin: 0 auto;	
}
.owl-carousel.owl-drag .owl-item {
	height:auto !important;	
}
.mm-listitem__btn .fa-external-link {
	padding:10px 20px;	
}
.owl-dots {
	margin-top:20px;	
}
.owl-pagination {
	margin-top:30px;	
}
.item-min-cart {
	margin-top:-10px !important;	
}
.cymot-home-sections {
	border:1px;
	border-color:white;
	border-style:solid;	
}
.header-hero-button h2 {
	margin: 0;
    position: absolute;
    z-index: 300;
    background: #f98d2c;
    right: 6%;
    bottom: 10%;
    color: #ffffff;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1.5em;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: #707070;
}
/* Stock level button as a tab */
#stocklevel a,.li_stocklevel a {
     background:#f78d2c !important;
     padding:0;
     color:#fff !important;
}
#stocklevel a.button span span {
     line-height:1em;
     height:auto;
     background:#f78d2c;
}
/* Brand logo on product pages */
.module-product-details .box-brand {
     max-width:100px;
     margin-bottom:-20px;
}

.add-cart-wrapper button.button span {
	color:#ffffff;
}
.xm-grid-product .owl-carousel .owl-item img {
	max-width:75%;
	margin: 0 auto;
}
.header-search-bar .button-search {
    background: var(--darkBlue);
    width: 45px;
    z-index: 1000;
    border-radius: 20px;
    margin-right: -2px;	
}
.header-search-bar .inline-search-results {
	left:24.5% !important;
	border-radius:30px !important;
	width:51% !important;
}
.header-search-bar {
	padding-bottom:10px;
}
.header-search-bar #search {
	text-align: center;
    background: #ececec;
    border-radius: 15px;
    width: 100%;
    margin: 0 auto;
    float: unset;
    border-bottom: 1px;
    border-bottom-color: var(--darkBlue);
    border-bottom-style: solid;
}
.header-msg-bar {
	margin: 0 auto;
}
.isPublic .price-per-unit {
	display:none;
}

.breadcrumbs {
	margin-top:15px;	
}
.module-category-misc .sort-by {
	text-align:right;
	margin:10px auto;
}
.mobile-floating-cats {
	position: fixed !important;
    z-index: 1000;
    background: #fff;
    border-style: solid;
    border-color: #f19639;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    left: 0;	
}
.cymot-search {
	background:#fff;	
}
.cymot-mobile #mini-cart .caret {
	display:none;	
}
.cymot-mobile  .links>li {
	margin:0 15px;	
}
.cymot-mobile .header .item-right {
	width:unset !important;	
}
.banner-css {
	position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 20px 0;
    font-size: 1.2em;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    color: #000;
}
.banner-css h2 {
	font-size: 2em;	
	font-weight:700;
	color:#fff;
}
.banner-css-orange {
	background:var(--darkBlue);
	opacity:0.9;
}
.banner-shop-now {
	font-weight:700;
	background:#fff;
	padding:10px;
	border-radius:20px;
	margin:10px;
	text-transform:uppercase;
}
.customers-who-bought .owl-carousel.owl-drag .owl-item {
	max-width:200px;	
}
.homepage-heroslider-nomobile {
	background:#000;	
}
.product-search-container .pagination strong {
	display:none;	
}
.product-search-container .add-cart-button span {
	background-color: var(--darkBlue);
	color:#fff;
}
.product-search-container .price-box {
	margin-top:15px;	
}
.product-search-container .status-in, .product-search-container .stock-in-text {
    color: var(--darkBlue);
    font-size:1.3em;
}
.category-products-listing .product-image-wrapper {
	text-align:center;	
}
.footercopyright {
	color:#555;
	background-color:#edeef0 !important;
	padding-bottom:25px;
}
.add-to-links span.icon-hover:hover {
	background:none;	
}
.customer-who-bought {
	border-color:var(--darkBlue);
}
.box-tabs {
	margin-top:25px;	
}
#review-form .buttons-set {
	padding-top:10px;	
}
.review-wrapper, #review-form {
	display:none;	
}
.module-product-details .price-box p {
	margin:0;
	line-height:1.5;	
}
.module-product-details #regular-price {
	color:#949191;	
}
.module-product-details #old-price {
	text-decoration:line-through;	
}
.module-product-details #now-price {
	font-size:30px;
	font-weight:500;
	color:red;
}
.feature-icon-hover:hover span.icon.icon-color-productview {
    background-color: var(--darkBlue);
}
.gen-tabs .tabs a {
	border:1px;
	border-color:var(--darkBlue);
	border-style:solid;
	background:#ffffff;
}
.gen-tabs .tabs a.current {
    background-color: var(--darkBlue);
    color: #ffffff;
    border-color:var(--darkBlue);
	border-style:solid;
    font-weight:300;
    margin:0;
    padding-top:0;
}
.gen-tabs .tabs a {
    line-height: 2em;
    padding: 0 15px;
    font-weight:300;
}
.module-product-details .product-name h1 {
	text-transform:uppercase;	
}
.module-product-details .price {
	color:#949191;	
}
.rating-stars-links {
    margin-top:-4px;	
}
.cancel-off-png, .cancel-on-png, .star-half-png, .star-off-png, .star-on-png {
    color: var(--darkBlue);
}
#unit-messure-wrapper {
	margin-top:20px;
}
.product-shop .add-to-links {
	list-style:none;	
}
.product-shop .add-to-links .fa {
	background:none;
}
.wishlist-page h2 {
	text-align:center;
	font-size:2.5em;	
}
.wishlist-wrapper {
	text-align:center;
	margin:20px auto;	
}
.wishlist-page .heading {
	font-size:2em;
}
.wishlist-page .image {
	margin-top:10px;	
}
.footer-primary-bottom-spacing {
	border:0;
	padding:30px 0 0 0;	
}
.home .main {
	margin:0;
	width:100%;	
}
.image-hover {
     position:relative;
     top:110px;
     background:var(--darkBlue);
     padding:10px 15px;
     text-transform:uppercase;
     color:#ffffff;
     font-weight:700;
     cursor:pointer;
     border-style:solid;
     border-width:1px;
     border-color:#707070;
     border-radius: 20px;
     display:inline-block;
     min-width:200px;
}
.image-hover a {
	color:#fff;
}
.image-hover a:hover {
	color:#23475a;	
}
.mobile-slider {
	min-height:300px;
	background:#333 !important;
}
.promo-header {
    font-family:var(--fontFam);
    font-weight: 700;
    color: #fff;
    background-color: var(--darkBlue);
    padding-top: 11px;
    font-size: 1em;
    letter-spacing: 0.5px;
}

.fa-chevron-right:before {
    content: "\f054";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

.slider-arrows2 .owl-controls div {
    background-color: transparent;
}

.page-content h2 {
    font-size: 2em;
}

/*Newsletter Module Home page*/
#subscribe-form form {
    display: block;
    text-align: center;
    text-transform: capitalize;
}

#subscribe-form {
    padding: 1%;
}

.section-title.padding-right {
    padding-right: 0;
}

.newsletter-label-input_email {
    display: none;
}

.newsletter-wrapper {
    background-color: var(--darkBlue);
    width: 100%;
}

.newsletter .section-title {
    border-bottom: none;
    background-color: var(--darkBlue);
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    letter-spacing: 0.5px;
}

.newsletter-wrapper .text_title {
    clear: both;
    display: block;
    padding: 10px;
    color: #fff;
}

.newsletter .inputs {
    display: block;
    width: 50%;
    margin: 0 auto;
}

#subscribe-form .input-box {
    float: left;
    margin: 0 1%;
}

#subscribe-form .input-text, #subscribe-form .button {
    font-family:var(--fontFam);
}

.input-box.newsletter-field-input_email {
    width: 78%;
}

.input-text.required-entry.validate-input_email {
    width: 100% !important;
}

.button.btn-inline.newsletter-button {
    width: 20%;
    margin: 0;
    border-radius: 30px;
}

.button.button.btn-inline.newsletter-button span span:hover {
    background-color: #fff;
    color: #1d262b;
    border-radius: 30px;
}

.newsletter button.button span {
    width: 100%;
    text-transform: uppercase;
    border-radius: 30px;
}

.block-account .block-title {
    background: none;
}

.product-options.border-out {
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
}

.product-options.border-out:after {
    content: 'Please select the options';
    color: red;
    font-size: 1.2em;
}

.form-list .field {
    position: relative;
}

/* ================================================ */
/* Footer */
/* ================================================ */


/** extra **/
.item-user-links {
    margin-top:5px;
}

.header .logo strong {
    position:relative;
}
#root-wrapper span.fa {
    color:var(--darkBlue)!important;
    margin:5px 0!important;
}
.header-top.header {
    text-align:center;
}
.module-category-filters .grid12-0, .module-header-multi .grid12-0 {
    vertical-align:top;
}
.nav-search, .nav-cart, .nav-logo {
    float:none !important;
    display:inline-block !important;
}
.form-search .button {
    color:#aaa!important;
}
.dropdown-toggle.cover>div {
    padding:0;
}
.nav-search.fly-out {
    margin:0;
}

.mm-panel_opened .mh-filters-btn {
    display: none !important;
}

@media only screen and (max-width:960px) {
	.homepage-hotdeals .owl-carousel .owl-item img {
		width:unset;
		max-height:200px;	
	}
	.homepage-hotdeals .product-name {
		margin:0 auto;
		text-align:center;
		width:100%;
	}
	.sidebar .block-content #filter_list_box dd ol {
		width:180px;
		max-height:unset;
	}
	.sidebar .block-content #filter_list_box {
	    overflow: scroll;
	    position: relative;
	    max-height: 280px;
	    width: 100%;
	    background: white;	
	}
    .mmenu-target-filter i {
    	font-size: 1.5em;
	    display: inline-block;
	    vertical-align: middle;
	    margin: 0 10px;
	    font-style: normal;
    }
	.mmenu-target-filter .block-content {
		display:none;	
	}
	.mmenu-target-filter .block {
		margin:0 auto;	
	}
	.mmenu-target-filter .block-content {
		padding-left:5px;	
	}
	.sidebar-wrapper .block-title {
		font-size:1em;	
	}
	.sidebar-wrapper .accordion-style1 li a {
		font-size:1em;
		padding:0px 15px;	
	}
	#stocklevel a {
     background:#000;
	}
	.rating-stars-links {
    	margin-top: 40px;
	}
	.cymot-search .fa-search {
		display:none;	
	}
	.cymot-search {
		padding:10px 0;	
	}
	.search input {
		border-width: 2px !important;
	    width: 95% !important;
	    margin: 0 auto;	
	}
	.grid-full {
		width:100%;
		margin: 0 auto;
		padding:0;	
	}
    .nav-logo {
        width:200px;
        float:left !important;
        vertical-align:bottom;
        text-align:left;
        padding:10px 0;
        line-height:1em;
    }
    .display-mode-grid .sidebar-wrapper.mmenu-hide {
        display:block !important;
    }
    .display-mode-grid .sidebar-wrapper.mmenu-hide .sidebar-filter {
        display:none !important;
    }
    .header .module-mmenu {
    	position:absolute !important;
    	top:0;
    	left:0;	
    }
    .module-category-misc .module-mmenu {
    	display:none;	
    }
}
@media only screen and (max-width:767px) {
    #new-advanced-search-box .results-right .products ul li.grow {
        width:48% !important;
        padding:5px 0;
        text-align:center;
    }
    .img-box-style1 .product-image {
    	width:300px;	
    }
    .cymot-product-image {
    	margin-top:-18px;	
    }
}

/* width > 960px */
@media only screen and (min-width: 960px) {
    /* Hide */
    .hide-above-960 { display:none !important; }
    
}

@media only screen and (max-width:1200px) {
	.module-product-details .grid12-2 {
		width:100%;
		margin:10px auto;	
	}
	.header-msg-bar,.cymot-mobile,.cymot-mobile .grid-column-wrapper,.top-banner,.b2c-banner,.b2b-banner,.homepage-hotdeals,.our-service-offerings {
		overflow:hidden;	
	}
	.products-grid .item .add-to-links, .products-grid.category-products-grid.hover-effect .item .add-to-links {
		display:block;	
	}
	.footer-container #subscribe-form label {
		display:none;	
	}
	.footer-container #subscribe-form span, #subscribe-form div, #subscribe-form input, #subscribe-form button {
		float:none !important;
		display:inline-block !important;
	}
	.footer-container .homepage-newsletter .section {
		margin:0 auto;
		float:none !important;	
	}
	.footer-container #subscribe-form .inputs {
		width:100%;
	}
	.footer-container #subscribe-form .button {
		width:100%;	
	}
	.footer-container #subscribe-form .button span {
		margin-top:11px;
		width:30%;
	}
	.footer-container .homepage-newsletter #subscribe-form {
	    margin: 10px auto;
	    width: 98%;
	    padding: 0;
	    overflow:none;
	}
	.footer-container .item-right {
		margin:0 auto;	
	}
	#review-form, .review-wrapper {
		display:block;	
	}
}

@media only screen and (min-width: 768px) and (max-width:1075px) {
	.image-hover {
		padding:10px 5px;
		font-size:0.7em;
	}
	.cymot-home-sections {
		background-position:center left !important;	
	}
}

@media only screen and (max-width:767px) {
	.breadcrumbs {
		margin-top:0px;	
	}
	.products-list .product-image-wrapper, .products-list .product-shop, .products-list .right-column {
		width:98%;	
	}
	.add-cart-wrapper {
		text-align:center;	
	}
	.service-offering img {
		max-width:50%;
	}
	.service-offering {
		margin: 0 auto;
	}
	.sub-footer h6 {
		margin-top:30px;	
	}
	.social-icons .item-right {
		float:none;	
		margin:0;
	}
	.social-icons {
		float:left !important;
		margin-top:20px;	
	}
}

@media only screen and (max-width:400px) {
	.banner-shop-now {
		display:inline-block;	
	}
	.banner-css {
		padding:5px 0;	
	}
	.banner-css h2 {
		font-size:1.2em;	
	}
	.shop-latest p {
		margin:1%;
		line-height:20px;
	}
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
	.itemgrid.itemgrid-adaptive .item {
	    width: 100%;
	    clear: none!important;
	    border-bottom: solid;
	    border-bottom-color: #ececec;
	    border-bottom-width: 1px;
	}
}
/* Added by Bradly for FireSafety*/
button, input, select, textarea {
	font-family:var(--fontFam);	
}
:focus { outline: solid #00d8ff } 
